import React, { useEffect } from 'react';
import AdminContainer from '../../../../layout/admin/container';
import { useBreadcrumbs } from '../../../../../contexts/breadcrumbs-provider';
import Heading from '../../../../layout/admin/heading';
import AutoForm from '../../../../form/auto-form';
import SEO from '../../../../layout/seo';

const AdminDiscountCode = ({ discountCodeId }) => {
  const { setBreadcrumbs } = useBreadcrumbs();

  useEffect(() => {
    setBreadcrumbs([
      {
        href: '/app/admin/discount-codes',
        label: 'Discount Codes',
      },
      {
        href: `/app/admin/discount-code${discountCodeId ? `/edit?id=${discountCodeId}` : ''}`,
        label: `${discountCodeId ? 'Update' : 'Create'} Discount Code`,
      },
    ]);
  }, [discountCodeId]);


  const discountCodeForm = {
    type: 'post',
    fetchRoute: discountCodeId ? `/admin/discount-code/${discountCodeId}` : null,
    fetchErrorRedirect: '/admin/discount-codes',
    fetchErrorMessage: 'There was an error while attempting to load the requested discount code.',
    postRoute: `/admin/discount-code${discountCodeId ? `/${discountCodeId}` : ''}`,
    successRedirect: '/app/admin/discount-codes',
    successNotification: 'The discount code was saved.',
    fields: [
      {
        name: 'code',
        label: 'Code',
        type: 'text',
        required: true,
        helpText: 'Codes are case insensitive, so love10 and LOVE10 will both work.',
      },
      {
        name: 'type',
        label: 'Type',
        type: 'select',
        options: [
          {
            value: '',
            label: 'Select...',
          },
          {
            value: 'absolute',
            label: 'Absolute Value',
          },
          {
            value: 'percent',
            label: 'Percent',
          },
        ],
        required: true,
      },
      {
        name: 'value',
        label: 'Value',
        type: 'text',
        required: true,
        helpText: 'Only include numbers, no percent signs or dollar signs. Use whole numbers for percent discount, for example: enter 10 for a 10% off coupon.',
      },
      {
        name: 'expires_after',
        label: 'Expires After This Date',
        type: 'date',
        // required: true,
        helpText: 'Enter the last day that people can use this code.  Leave blank to create a discount code that never expires.',
        initialValue: (item) => {
          const { expires_after: endDate } = item || {};
          return endDate ? formatDay(endDate, 'MM/DD/Y') : null;
        },
      },
      {
        name: 'status',
        label: 'Status',
        type: 'select',
        options: [
          {
            value: '',
            label: 'Select...',
          },
          {
            value: 1,
            label: 'Active',
          },
          {
            value: 0,
            label: 'Inactive',
          },
        ],
        required: true,
      },
      
    ],
    submitLabel: `${discountCodeId ? 'Update' : 'Create'} Discount Code`,
  };

  return (
    <AdminContainer>
      <SEO title={`${discountCodeId ? 'Update' : 'Create'} Discount Code`} />
      <Heading title={`${discountCodeId ? 'Update' : 'Create'} Discount Code`} />
      <div className="max-w-2xl mx-auto py-12">
        <AutoForm {...discountCodeForm} />
      </div>
    </AdminContainer>
  );
}

export default AdminDiscountCode;
